import { default as maplibregl } from "maplibre-gl";

import workerUrl from "../dist/worker.umd.js?url";
import { OfflineMap } from "./offline-map";

const maplibreglOffline = maplibregl as any;
maplibreglOffline.OfflineMap = OfflineMap;
maplibreglOffline.workerUrl = workerUrl;

const addOfflinePaths = (style) => {
  if (style.sources.maptiler_planet) {
    style.sources.maptiler_planet = {
      path: "/map/layer-maptiler.mbtiles", // must be public path
      type: "mbtiles",
    };
  }

  // if (style.sources.contours) {
  //   style.sources.contours = {
  //     path: "/map/layer-maptiler-contours.mbtiles", // must be public path
  //     type: "mbtiles",
  //   };
  // }

  // if (style.sources.outdoor) {
  //   style.sources.outdoor = {
  //     path: "/map/layer-maptiler-outdoor.mbtiles", // must be public path
  //     type: "mbtiles",
  //   };
  // }

  // if (style.sources["terrain-rgb"]) {
  //   style.sources["terrain-rgb"] = {
  //     path: "/map/layer-maptiler-terrain-rgb.mbtiles", // must be public path
  //     type: "rasteroffline",
  //   };
  // }

  if (style.layers?.length) {
    style.layers = style.layers.map((layer) => {
      const textFont = layer.layout["text-font"];

      if (textFont?.length) {
        return {
          ...layer,
          layout: {
            ...layer.layout,
            // biome-ignore lint/complexity/useLiteralKeys: exact property name is expected from maplibre-gl
            ["text-font"]: textFont.filter((font) => font.includes("Roboto")),
          },
        };
      }

      return layer;
    });
  }

  // overwrite sprite and font paths
  style.sprite = "/map/sprite/sprite"; // "sprite" is used as a dynamic file name
  style.glyphs = "/map/glyphs/{fontstack}/{range}.pbf"; // does not work with multiple font declarations in style.json

  return style;
};

export { maplibreglOffline, addOfflinePaths };
